<template>
  <v-navigation-drawer right class="navigation-drawer" :value="dialog" width="400px" app>
    <v-card>
      <v-card-title class="pa-0">
        <v-app-bar color="teal darken-2" dark>
          <v-app-bar-title>Orden de Compra</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close </v-icon>
          </v-btn>
        </v-app-bar>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-alert dense text type="success">
          {{ this.client.name }}
        </v-alert>
        <v-data-table
          calculate-widths
          :headers="header"
          :items="cart"
          :items-per-page="-1"
          item-key="index"
          hide-default-footer
          sort-by="name"
          mobile-breakpoint="0"
          class="table-cursor"
          loading-text="Cargando ..."
        >
          <template v-slot:top></template>
          <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
            <div @click="editItm(item)">
              <b class="text-uppercase">{{
                item.product_name + " " + item.pack + " " + item.uom
              }}</b>
              <span v-html="item.description" />
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }" class="text-right">
            <span class="text-decoration-line-through red--text" v-if="item.discount"
              >{{ "$" + (parseFloat(item.price) * parseFloat(item.pack)).toLocaleString()
              }}<br
            /></span>
            <b>{{ "$" + Math.round(parseFloat(item.tItem)).toLocaleString(0) }}</b>
            <v-spacer></v-spacer>
            <v-btn icon x-small @click="deleteItem(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <template slot="body.append">
            <tr>
              <th class="totals">Totales</th>
              <th class="text-center totals">
                {{ sumField("cart", "qty").toLocaleString(2) }}
              </th>
              <th class="text-right totals">
                {{ sumField("cart", "tItem").toLocaleString(2) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <template v-slot:append>
      <v-form v-model="valid" ref="issueForm" @submit="send_order">
        <v-row class="pa-2">
          <v-col cols="12">
            <v-text-field
              v-model="delivery_issue.issueDate"
              type="date"
              hide-details="auto"
              label="Fecha de entrega"
              :rules="f_valid_dates"
              outlined
              required
            />
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="delivery_issue.issueTime"
              hide-details="auto"
              label="Horario de entrega"
              :items="delivery_times"
              :rules="f_required"
              outlined
              required
            />
          </v-col>
        </v-row>
        <v-toolbar dark color="teal darken-2 mt-3" dense>
          <v-spacer></v-spacer>
          <v-btn text rounded dark type="submit"> Enviar Orden </v-btn>
        </v-toolbar>
      </v-form>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { webserver, getIssueDate } from "../services/webserver.js";
export default {
  name: "Shoppingcart",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    cart: {
      type: Array,
      required: true,
      default: null,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  // mounted() {
  //   if (localStorage.cart) {
  //     this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
  //     this.cart.forEach((itm) => {
  //       itm.qty = parseFloat(itm.qty);
  //       itm.price = parseFloat(itm.price);
  //       if (itm.value_options) {
  //         itm.value_options = parseFloat(itm.value_options);
  //       } else {
  //         itm.value_options = 0;
  //       }
  //       itm.tItem =
  //         parseFloat(itm.qty) * (parseFloat(itm.price) + parseFloat(itm.value_options));
  //     });
  //   } else {
  //     this.cart = [];
  //   }
  //   this.updateTotals();
  // },
  data() {
    return {
      header: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Cant.",
          align: "center",
          value: "qty",
          width: 2,
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      header_selected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
        },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_valid_dates: [
        (v) => !!v || "El código es requerido",
        (v) => v >= getIssueDate(-1) || "Fecha no valida",
        (v) =>
          v <= getIssueDate(-15) || "Máximo 15 días de antelación : " + getIssueDate(-15),
      ],
      sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
      delivery_issue: { issueDate: getIssueDate(-1), issueTime: null },
      delivery_times: [
        "6:00 a.m. - 10:00 a.m.",
        "10:00 a.m. - 02:00 p.m.",
        "02:00 a.m. - 06:00 p.m.",
      ],
      valid: false,
      // cart: [],
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    editItm(e) {
      e.options_selected = JSON.stringify(e.options_selected);
      this.$emit("edit", e);
    },
    send_order(e) {
      e.preventDefault();
      this.valid = this.$refs.issueForm.validate();
      if (this.valid) {
        //this.$emit("send", true);
        this.updateTotals();
        this.cart.forEach((itm) => {
          if (itm.product_options)
            itm.product_options = JSON.stringify(itm.product_options);
          delete itm.percent;
        });

        var order = {
          pos: this.$store.getters.company,
          party: this.client,
          total: this.sc.total,
          items: this.cart,
        };

        order.total = this.sc.total;
        order.account = this.$store.getters.company.account;
        order.doc_type = "OR";
        order.store = this.$store.getters.company.code;
        order.userId = this.$store.getters.profile.code;
        order.agent = "WEB";
        order.items = this.cart;
        order.status = 0;
        order.delivery_date = this.delivery_issue.issueDate;
        order.delivery_time = this.delivery_issue.issueTime;
        webserver("put_doc", order, (data) => {
          //console.log(data);
          if (data.header) {
            this.cart = [];
            localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
            this.$emit("update", true);
            this.$router.push({ name: "Checkout", params: { did: data.header.doc_code } });
          }
          //this.$router.push("../views/Checkout/?did=" + data.header.doc_code);
          
        });
      }
    },
    deleteItem(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      localStorage.cart = Buffer.from(JSON.stringify(this.cart)).toString("base64");
      this.$emit("update", true);
      this.updateTotals();
    },

    updateTotals() {
      var itms = this.cart;
      var qty = 0;
      var tax = 0;
      var total = 0;
      itms.forEach((itm) => {
        qty += parseFloat(itm.qty);
        if (itm.product_options) {
          var slt = itm.product_options;
          var tselected = 0;
          Object.keys(slt).forEach((key) => {
            slt[key].forEach((tm) => {
              if (tm.selected) {
                tselected += parseFloat(tm.price);
              }
            });
          });
          itm.value_options = tselected;
        }
        total += parseFloat(itm.tItem) + parseFloat(itm.value_options);
        tax +=
          ((parseFloat(itm.tItem) + parseFloat(itm.value_options)) *
            parseFloat(itm.tax)) /
          100;
      });
      this.sc.qty = qty;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = tax;
      this.sc.total = total;
    },
    selecttype(e) {
      return e[0].unique;
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
  watch: {
    // dialog: function () {
    //   if (localStorage.cart) {
    //     this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
    //     this.cart.forEach((itm) => {
    //       itm.qty = parseFloat(itm.qty);
    //       itm.price = parseFloat(itm.price);
    //       if (itm.value_options) {
    //         itm.value_options = parseFloat(itm.value_options);
    //       } else {
    //         itm.value_options = 0;
    //       }
    //       itm.tItem =
    //         parseFloat(itm.qty) * (parseFloat(itm.price) + parseFloat(itm.value_options));
    //     });
    //   } else {
    //     this.cart = [];
    //   }
    //   this.updateTotals();
    // },
  },
};
</script>

<style>
.totals {
  font-size: 0.875rem !important;
}
</style>
