<template>
  <div>
    <v-toolbar fixed class="navbar" dense>
      <v-tabs center-active>
        <v-tab
          v-for="catego in smart_categories"
          :key="catego"
          color="info"
          @click="changeData(catego)"
        >
          {{ catego }}
        </v-tab>
      </v-tabs>

      <template v-if="!isXs">
        <v-text-field
          light
          placeholder="Buscar"
          v-model="tosearch"
          append-icon="mdi-magnify"
          clearable
          outlined
          hide-details
          filled
          background-color="white"
          dense
          @keyup="findProduct(this)"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="transparent"
          class="ml-3"
          elevation="0"
          @click="cart_dialog = !cart_dialog"
          :disabled="!sc.qty > 0"
        >
          <v-icon color="warning">mdi-cart-outline</v-icon>
          {{ sc.qty }}
        </v-btn>
      </template>
    </v-toolbar>

    <v-bottom-navigation background-color="blue-grey lighten-5" app v-if="isXs">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="success" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-format-list-checkbox</v-icon>Lista
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in smart_categories" :key="index">
            <v-list-item-title @click="changeData(item)">{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-text-field
        light
        placeholder="Buscar"
        rounded
        class="pt-2"
        full-width
        v-model="tosearch"
        append-icon="mdi-magnify"
        clearable
        outlined
        hide-details
        filled
        background-color="white"
        dense
        @keyup="findProduct(this)"
      />
      <v-btn
        color="transparent"
        elevation="0"
        @click="cart_dialog = !cart_dialog"
        :disabled="!sc.qty > 0"
      >
        <v-icon color="warning">mdi-cart-outline</v-icon>
        {{ sc.qty }}
      </v-btn>
    </v-bottom-navigation>


            <!-- <v-btn  fixed top left color="transparent" class="topAlert">
        <v-icon>mdi-arrow-up</v-icon> {{this.client.name}}
      </v-btn> -->

    <v-container fill-height class="toptop">
      
   


<!-- 
     <v-btn fab dark fixed top color="primary" class="toptop" to="Cart">
                    <v-icon>mdi-cart-outline</v-icon>{{ client.name }}
      </v-btn> -->


      <template>
      
       <v-alert
        dense
        text
        type="success"

      >
        {{this.client.name}}
      </v-alert>
        <v-list v-if="isXs" width="100%" elevation="0">
          <v-list-item
            v-for="(child, index) in smart_lst_selected"
            :key="index"
            @click="addItem(child)"
            class="product_item"
          >
            <v-btn
              v-if="child.discount"
              color="success"
              elevation="10"
              rounded
              dark
              small
              absolute
              top
              rigth
              class="topleft"
              >{{ "-" + child.percent + "%" }}</v-btn
            >
            <v-list-item-avatar size="80">
              <v-img
                contain
                :src="getImagePhoto(child.account, child.store, child.picture)"
                v-if="child.picture"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card flat>
                <v-card-title class="font-weight-bold pa-0">{{
                  child.product_name
                }}</v-card-title>

                <v-card-text
                  class="pa-0"
                  v-html="child.description"
                  v-if="child.description"
                ></v-card-text>
                <v-card-text class="pa-0" v-else>
                  {{ child.category + " / " + child.subcategory }}
                </v-card-text>

                <v-card-actions class="pa-0">
                  <v-spacer></v-spacer>
                  {{
                    "$" +
                    Math.round(
                      parseFloat(child.price) - parseFloat(child.discount)
                    ).toLocaleString(0) +
                    " x " +
                    child.uom
                  }}
                </v-card-actions>
                <v-btn
                  v-if="child.discount"
                  color="red"
                  elevation="0"
                  text
                  dark
                  small
                  absolute
                  bottom
                  right
                  class="btnprice"
                >
                  <span class="text-decoration-line-through">{{
                    "$" + parseFloat(child.price).toLocaleString()
                  }}</span>
                </v-btn>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-row fill-height justify="center" align="center" v-else>
          <v-col
            cols="12"
            md="3"
            sm="4"
            lg="2"
            class="text-center"
            v-for="(child, index) in smart_lst_selected"
            :key="index"
          >
            <v-card elevation="1" tile height="100%" @click="addItem(child)">
              <v-img
                height="250px"
                width="100%"
                contain
                :src="getImagePhoto(child.account, child.store, child.picture)"
                v-if="child.picture"
              ></v-img>
              <v-btn
                v-if="child.discount"
                color="success"
                elevation="10"
                rounded
                dark
                small
                absolute
                top
                right
                >{{ "-" + child.percent + "%" }}</v-btn
              >
              <v-card-title class="pt-0 text-truncate product_title">
                <b>{{ child.product_name }}</b>
              </v-card-title>
              <v-card-text
                v-if="child.description"
                class="product_text"
                v-html="child.description"
              ></v-card-text>
              <v-spacer></v-spacer>
              <v-btn
                v-if="child.discount"
                color="red"
                elevation="0"
                text
                dark
                small
                absolute
                bottom
                right
                class="btnprice"
              >
                <span class="text-decoration-line-through">{{
                  "$" + parseFloat(child.price).toLocaleString()
                }}</span>
              </v-btn>

              <v-card-actions v-if="child.pack > 1">
                <span>{{
                  parseFloat(child.price_uom).toFixed(2) + " x " + child.normalized_uom
                }}</span>
                <v-spacer></v-spacer>
                <span class="font-weight-bold text-lowercase">
                  {{
                    "$" +
                    Math.round(
                      parseFloat(child.price) - parseFloat(child.discount)
                    ).toLocaleString() +
                    " x " +
                    child.uom
                  }}
                </span>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-snackbar
        v-model="snackToast.dialog"
        :timeout="snackToast.timeout_error"
        dark
        :color="snackToast.color"
        elevation="24"
        >{{ this.snackToast.text }}</v-snackbar
      >

      <CartAdd
        :dialog="add_dialog"
        :itemSelected="itemSelected"
        :addItm="addItm"
        @close="
          add_dialog = false;
          updateTotals();
        "
      />

      <ShoppingCart
        :dialog="cart_dialog"
        :client="client"
        :cart="cart"
        @update="updateTotals"
        @edit="editItem"
        @close="cart_dialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import CartAdd from "../../components/Cart_add.vue";
import ShoppingCart from "../../components/Cart.vue";
import createItem from "../../utils/createItem.js";
import createClient from "../../utils/createParty.js";

export default {
  components: { CartAdd, ShoppingCart },
  data: () => ({
    cart_dialog: false,
    isXs: false,
    categories: [],
    items: [
      {
        id: 3,
        name: "Mis pedidos",
        description: "Creear, editar o eliminar pedidos.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
      {
        id: 1,
        name: "Lista de Precios",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "warning",
        to: "Proveedores",
      },
      {
        id: 2,
        name: "Proveedores",
        description: "Creear, editar o eliminar proveedores.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },

      {
        id: 4,
        name: "Mi perfil",
        description: "Editar perfil, logo, información de contacto.",
        icon: "mdi-file-document-edit-outline",
        to: "Material",
        color: "warning",
      },
    ],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    menu: [
      {
        id: 1,
        name: "Hacer pedido",
        description: "Creear, editar o eliminar productos de la lista de precios.",
        icon: "mdi-store-outline",
        color: "#455A64",
        to: "Inicio",
      },
      {
        id: 2,
        name: "Historico de Pedidos",
        description: "Creear, editar o eliminar clientes.",
        icon: "mdi-file-document-edit-outline",
        to: "MyOrders",
        color: "#455A64",
      },
    ],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    headers: {
      false: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Size",
          align: "center",
          value: "size",
          width: 2,
        },
        {
          text: "",
          align: "center",
          value: "price_uom",
          width: 2,
        },
        {
          text: "Pack.",
          align: "center",
          value: "pack",
          width: 2,
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
      true: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          with: "1",
        },
        {
          text: "Producto",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
        },
      ],
    },
    header_orders: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "issue_date",
        dataType: "date",
      },
      {
        text: "Orden",
        align: "start",
        value: "doc_code",
        dataType: "text",
      },
      {
        text: "Valor",
        align: "end",
        sortable: true,
        value: "total",
      },
    ],
    snackToast: {
      timeout_error: 500,
      dialog: false,
      text: "",
      color: "warning",
    },
    tosearch: "",
    catetory: "",
    reg_dialog: false,
    cart: [],
    client: createClient(),
  }),
  methods: {
    changeData(e) {
      this.catetory = e;
      this.smart_lst_selected = this.menu[e];
    },
    findProduct() {
      if (this.tosearch) {
        this.smart_lst_selected = this.smart_lst.filter((o) => {
          if (o.product_name.toLowerCase().includes(this.tosearch.toLowerCase())) {
            return true;
          }
        });
      } else {
        this.smart_lst_selected = this.smart_lst;
      }
    },
    onResize() {
      this.isXs = window.innerWidth < 500;
    },
    openLink(e) {
      this.$router.push("/" + e + "/");
    },
    sumField(fld, key) {
      return this[fld].reduce((a, b) => a + (b[key] || 0), 0);
    },
    get_bestprice() {
      var qry = {
        account: this.$store.state.company.account,
        // table: "catalogue",
        // filters: [{ field: "store", operator: "=", value: "mayorista.app" }],
      };
      this.loading_status = true;
      webserver("get_bestprice", qry, (data) => {
        //console.log(data);
        data.forEach((rg) => {
          rg.qty = 1;
          rg.active = true;
          rg.note = "";
          if (rg.price) rg.price = parseFloat(rg.price);
          if (rg.pack) rg.pack = parseFloat(rg.pack);
          if (rg.tax) rg.tax = parseFloat(rg.tax);
          if (rg.discount) rg.discount = parseFloat(rg.discount);
          if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
          if (rg.product_options) {
            rg.product_options = JSON.parse(rg.product_options);
          } else rg.product_options = null;
          if (rg.value_options) {
            rg.value_options = parseFloat(rg.value_options);
          } else rg.value_options = 0;
          rg.percent = parseFloat(rg.discount);
          rg.discount = Math.round(
            parseFloat(parseFloat(rg.price) * (rg.discount / 100))
          );
          rg.tItem =
            parseFloat(rg.qty) *
            parseFloat(rg.pack) *
            (parseFloat(rg.price) - parseFloat(rg.discount));

          delete rg.codelp;
          delete rg.costlp;
          delete rg.cost_max;
          delete rg.cost_avg;
          delete rg.sup_qty;
          delete rg.utility;
          delete rg.bp_code;
        });
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }

        data.sort(function (a, b) {
          var textA = a.category.toUpperCase();
          var textB = b.category.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.smart_ctgry = ctgry;
        this.smart_categories = Object.keys(ctgry).sort();
        console.log(this.smart_categories);
        this.smart_lst_selected = data;
        this.menu = ctgry;
        this.loading_status = false;
        this.smart_lst = data;
      });
    },
    getbgpic(ctgry) {
      var phtslst = [];
      if (ctgry) {
        phtslst = this.menu[ctgry];
      } else {
        phtslst = this.menu;
      }
      if (phtslst.length > 0) {
        phtslst = phtslst.filter(function (el) {
          return el.picture !== "";
        });
      }

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getImagePhoto: function (account, store, src) {
      store = "000000";
      var url = "https://mayorista.app/photos/" + account + "/" + store + "/" + src;
      //console.log(url);

      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    editProduct(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateTotals() {
      if (localStorage.cart) {
        this.cart = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        this.cart.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.price = parseFloat(itm.price);
          if (itm.value_options) {
            itm.value_options = parseFloat(itm.value_options);
          } else {
            itm.value_options = 0;
          }
        });
        // this.updateTotals();
      } else {
        this.cart = [];
      }
      var itms = this.cart;
      var qty = 0;
      var tax = 0;
      var total = 0;
      itms.forEach((itm) => {
        qty += parseFloat(itm.qty);
        total +=
          parseFloat(itm.qty) * (parseFloat(itm.price) + parseFloat(itm.value_options));
        tax +=
          (parseFloat(itm.qty) *
            (parseFloat(itm.price) + parseFloat(itm.value_options)) *
            parseFloat(itm.tax)) /
          100;
      });
      this.sc.qty = qty;
      this.sc.subtotal = 0;
      this.sc.discount = 0;
      this.sc.tax = tax;
      this.sc.total = total;
      this.snackToast = {
        dialog: true,
        text: "Pedido actualizado.",
        color: "warning",
        timeout_error: 500,
      };
    },
    addItem(e) {
      console.log(e);
      this.addItm = true;
      this.itemSelected = { ...e };
      this.Scart = false;
      this.add_dialog = true;
    },
    editItem(e) {
      console.log(e);
      this.addItm = false;
      this.options_selected = null;
      this.optSelected = [];
      if (e.options_selected) {
        e.options_selected = JSON.parse(e.options_selected);
      }
      // if (e.product_options) {
      //   var opt = JSON.parse(e.product_options);
      //   if (opt.data) {
      //     this.options_selected = opt.data.reduce(function (r, a) {
      //       r[a.category] = r[a.category] || [];
      //       r[a.category].push(a);
      //       return r;
      //     }, Object.create(null));
      //     var sel = Object.keys(this.options_selected);
      //     sel.forEach((rg) => {
      //       this.optionsLst[rg] = [];
      //     });
      //   }
      // }

      this.itemSelected = e;

      //this.Scart = false;
      this.add_dialog = true;
    },
    save_order() {
      console.log(this.$store.getters.profile);
      this.get_oders();
      this.tab = "orders";
    },
    get_store() {
      var qry = {
        table: "stores",
        filters: [{ field: "code", operator: "=", value: "mayorista.app" }],
      };
      webserver("get_table_clients", qry, (data) => {
        console.log(data);
        this.$store.dispatch("setCompany", data[0]);
        this.get_bestprice();
      });
    },
    get_oders() {
      var qry = {
        table: "docs",
        filters: [
          { field: "doc_type", operator: "=", value: "OR" },
          { field: "store", operator: "=", value: "mayorista.app" },
          {
            field: "userId",
            operator: "=",
            value: this.$store.getters.profile.code,
          },
        ],
      };
      webserver("get_table_clients", qry, (data) => {
        console.log(data);
        this.orders = data;
      });
    },
  },
  mounted() {
    if (this.$route.params) {
      this.client =this.$route.params;
    } else {
      this.$router.push("Docs");
    }
    window.addEventListener("resize", this.onResize, { passive: true });
    // this.$store.dispatch("set_menuItems", this.menu);
    // this.$store.dispatch("setNavStatus", true);
    // this.$store.dispatch("set_menu", false);
    this.get_store();
    this.updateTotals();
    this.onResize();
  },
  watch: {},
};
</script>

<style lang="css" scope>
.product_title {
  font-size: 1rem !important;
  font-weight: bolder !important;
  word-break: normal !important;
  color: #455a64;
}

.product_item {
  padding: 0px;
  border-style: none;
  border-color: #cfd8dc;
  border-width: 0.1px;
  border-bottom-style: solid;
}

.topleft {
  top: 10px !important;
  right: 10px !important;
  z-index: 1;
}

.toptop {
  padding-top: 70px !important;
  min-height: 80vh;
}

.topAlert {
  
  top: 500;
  

}
.btom {
  bottom: 0px !important;
}

.btnprice {
  bottom: 20px !important;
  right: 5px !important;
}

.price {
  font-size: 0.8rem;
}

.navbar {
  overflow: hidden;
  position: fixed;
  /* Set the navbar to fixed position */
  top: 56  !important;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 2;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

.tshadow {
  text-shadow: black -1px 2px 6px;
  color: white;
  font-size: 2em;
}

.titleCentered {
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product_text {
  height: 120px !important;
  /* overflow-y: auto !important; */
}
</style>
